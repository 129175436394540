/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const BeneficiarySvg = props => {
    const finalProps = {
        ...props,
        viewBox: '0 0 1024 1024',
    };
    return (
        <SvgIcon {...finalProps}>
            <path d="M627.606483 643.35977c4.952802 4.242627 11.329023 6.903224 18.371416 6.903224 15.481599 0 28.025287-12.360516 28.025287-27.591405 0.022513-9.125845-4.585435-17.176195-11.580756-22.199605 0.022513-0.092098 0.022513-0.231267 0.022513-0.321318-27.889187-20.642133-57.269331-34.858926-85.043908-44.584428 66.488297-40.364313 111.76448-111.349017 111.76448-194.900945 0-126.646422-104.69855-226.873124-232.05617-226.873124-127.335107 0-232.011144 101.625562-232.011144 228.294496 0 82.130555 43.852764 154.48956 110.363573 193.479573-191.002149 68.208475-207.974705 261.689071-207.974705 264.48679-1.422396 15.32094 9.885138 29.195949 25.457812 29.195949 15.550161 1.39886 29.701462-9.725502 31.122835-25.067932 0-9.724479 21.214161-221.323735 270.197862-235.222279 6.214539 0 95.868441-3.278673 173.249833 54.42454C627.561457 643.382283 627.58397 643.35977 627.606483 643.35977zM457.108321 533.273513c-96.189759 0-175.406962-76.535117-175.406962-172.609243 0-96.028077 79.217203-171.198104 175.406962-171.198104 96.234785 0 175.428452 76.54535 175.428452 172.60822C632.536772 458.091206 553.343105 533.273513 457.108321 533.273513zM854.869486 639.32385 689.210539 827.552839l-87.335091-73.141834-48.944735 21.422915 136.049582 114.374934 207.653387-236.184187L854.869486 639.32385z" />
        </SvgIcon>
    );
};

export default BeneficiarySvg;
