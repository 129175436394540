/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EnketoSvgComponent = props => {
    const finalProps = {
        ...props,
        viewBox: '0 0 405 400',
    };
    return (
        <SvgIcon {...finalProps}>
            <g>
                <path
                    fill="#929497"
                    d="M799,384.32481c0,22.16578.16974,44.33327-.05344,66.49678-.18017,17.89133-3.68219,35.14347-11.97335,51.15058-15.84745,30.59558-40.87836,50.01776-74.1955,57.63547-28.78175,6.58071-56.64095,1.92382-81.667-13.89631-26.06929-16.47958-43.37947-39.81927-49.07949-70.68994a152.05928,152.05928,0,0,1-2.89812-26.374c-.295-42.66292-.16631-85.329-.118-127.99393.02164-19.13675,3.70846-37.42036,12.92031-54.41461,13.02477-24.02841,32.09563-41.11815,57.53858-51.28048,16.0339-6.40421,32.73883-8.25658,49.59707-6.77227,25.111,2.21092,47.00391,12.10206,65.487,29.70591a107.396,107.396,0,0,1,30.114,49.8309,119.62844,119.62844,0,0,1,4.14,28.60946C799.39734,338.98305,799,361.65906,799,384.32481Zm-28,.1673c0-22.9962.1629-45.99435-.1131-68.98724a71.97655,71.97655,0,0,0-2.46765-17.46857c-4.52838-17.00856-12.97961-31.63371-26.81389-43.02915-22.27711-18.34985-47.34546-23.59545-74.97125-16.20257-15.94832,4.2679-29.0903,13.1912-39.91734,25.83249-12.4616,14.54975-19.21176,31.51159-19.50063,50.23193-.67612,43.81584-.30228,87.64954-.12852,131.47515a133.82467,133.82467,0,0,0,1.94444,21.08093c4.02495,24.47345,17.6337,42.67664,38.46925,55.287,19.46359,11.78,40.48807,14.38006,62.6823,8.5603,19.80678-5.1937,35.1711-16.665,46.58223-33.06848,10.25012-14.73447,14.44232-31.67649,14.27939-49.72248C770.85266,427.15321,771,405.822,771,384.49211Z"
                    transform="translate(-484.02865 -185.0911)"
                />
                <path
                    d="M676.31212,378.63187v29.31022c6.50714,0,12.78792-.03811,19.068.009,9.34468.07,15.67052,5.97825,15.5922,14.49018-.07637,8.30077-6.12723,13.67551-15.58809,13.80083-5.99225.07941-11.98529.09876-17.97763.1734-.28794.00357-.57248.28143-1.11414.56564v29.96633q2.38257,0,4.7527.00015c8.82733.00565,17.65761-.12784,26.48111.059a14.05746,14.05746,0,0,1-.10559,28.11032q-23.73173.22819-47.467.00644c-8.475-.07492-14.73408-6.07565-15.61162-14.52154a43.63347,43.63347,0,0,1-.10916-4.49231q-.00784-53.464.00023-106.92789c.00467-13.21857,5.97024-19.11173,19.30316-19.11874,13.99059-.00737,27.98181-.07816,41.97147.03642,7.13112.05841,12.92466,4.57738,14.4571,10.851,1.47645,6.04425-.95092,12.66391-6.48243,15.39314a25.11094,25.11094,0,0,1-10.05687,2.1899C694.486,378.82481,685.53069,378.63187,676.31212,378.63187Z"
                    transform="translate(-484.02865 -185.0911)"
                />
                <path
                    fill="#f67135"
                    d="M657.41956,286.98286c3.3424,2.122,7.17706,3.74321,9.93811,6.45678,9.58459,9.41988,9.43324,9.55436,19.151.08005,6.91246-6.73933,13.8099-13.49513,20.79713-20.15642,6.68894-6.37693,14.3315-6.66712,19.818-.90036,5.318,5.58963,4.562,13.557-1.92812,19.84349q-19.01227,18.41574-38.05975,36.7951c-8.15721,7.86086-14.481,7.75568-22.27278-.41165-5.86055-6.143-11.74375-12.273-17.37521-18.62314-3.77425-4.25589-5.31127-9.12268-2.43609-14.6904,2.45908-4.76189,6.20775-7.20911,11.56849-7.16665Z"
                    transform="translate(-484.02865 -185.0911)"
                />
            </g>
        </SvgIcon>
    );
};

export default EnketoSvgComponent;
